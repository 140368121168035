/* You can add global styles to this file, and also import other style files */
@use "@taures/taures-theme/theme";
@use "@taures/taures-theme/abstracts" as abstracts;


@mixin overwrite-color($option,$color) {
  #{$option}: $color;
}

.p-listbox {
  @include overwrite-color(--p-listbox-option-color, abstracts.$neutrals-900);
  border: none !important;
  box-shadow: none;

  .p-listbox-list .p-listbox-option.p-listbox-option-selected {
    @include overwrite-color(--p-listbox-option-selected-background, abstracts.$primary-blue-800-opacity-a08);
    @include overwrite-color(--p-listbox-option-selected-focus-background, abstracts.$primary-blue-800-opacity-a08);
    @include overwrite-color(--p-listbox-option-selected-color, abstracts.$primary-blue-800-opacity-a87);
    @include overwrite-color(--p-listbox-option-selected-focus-color, abstracts.$primary-blue-800-opacity-a87);
  }

  .p-listbox-list-container {
    max-height: unset !important;
  }
}

.konzept-button {
  height: 70px;
  width: 100%;

  .p-button {
    padding: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

.p-button:disabled {
  background-color: abstracts.$neutrals-400;
  color: abstracts.$neutrals-800-opacity-a75;
}

  // THEME FIXES

html, body {
  height: 100%;
  overflow: hidden; //Check in newer Version if scrollbar works properly
}

main {
  overflow: auto;
  max-height: calc(100dvh - 10.2rem);
}

p-confirmDialog.line-height {
  line-height: 20px;
}

p-confirmDialog.warning-dialog .p-confirmdialog {
  border-left: abstracts.$m1 solid abstracts.$warning-orange-800;
}

p-confirmDialog.info-dialog .p-confirmdialog {
  border-left: abstracts.$m1 solid abstracts.$primary-blue-800;
}

p-confirmDialog .p-dialog.p-confirmdialog {
  width: 340px;

  .p-dialog-header {
    border-top-left-radius: 0;
  }

  .p-dialog-footer {
    border-bottom-left-radius: 0;
  }

  .p-confirmdialog-message {
    margin-left: 0;
  }

  .p-confirmdialog-accept, .p-confirmdialog-reject {
    .p-button-label, .p-button-icon-left {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.p-radiobutton .p-radiobutton-box {
  border-color: abstracts.$neutrals-500;
  background: abstracts.$neutrals-white;

  &.p-highlight {
    background-color: abstracts.$neutrals-white;

    &:not(.p-disabled):hover {
      background-color: abstracts.$neutrals-white;
    }
  }

  .p-radiobutton-icon {
    background-color: abstracts.$primary-blue-800;
    width: 13px;
    height: 13px;
  }
}

header .p-badge {
  padding: 0;
}

p-dynamicdialog .p-dialog .p-dialog-header .p-dialog-title {
  font-size: 17.5px;
}


//Material Symbols Outlined - Customer Search Button
.material-icon-button button {
  padding: 0.75rem 0.75rem;

  .material-symbols-outlined {
    font-size: 1.5rem;
  }
}

//PrimeNG 18 Migration & remove when fixed
.p-badge {
  border-radius: 50%;

  &.p-badge-warn {
    background-color: abstracts.$warning-orange-800;
    color: abstracts.$neutrals-900;
  }
}

.p-listbox-option {
  padding: abstracts.$m2 abstracts.$m1;
}

:root {
  --p-form-field-padding-x: 1rem;
  --p-form-field-padding-y: 0.75rem;
}

//Toast Message Fixes
.p-toast-message-warn .p-toast-detail {
  @include overwrite-color(--p-toast-warn-detail-color, abstracts.$warning-orange-800-opacity-a87);
}

.p-toast-message-error .p-toast-detail {
  @include overwrite-color(--p-toast-error-detail-color, abstracts.$danger-red-800-opacity-a87);
}

.p-toast-message-info .p-toast-detail {
  @include overwrite-color(--p-toast-info-detail-color, abstracts.$primary-blue-800-opacity-a87);
}

.p-toast-message-success .p-toast-detail {
  @include overwrite-color(--p-toast-success-detail-color, abstracts.$success-green-800-opacity-a87);
}
